<template>
  <div class="class">
    <div class="top">
       <el-button class="el-but2" type="primary" @click="go()" >返回</el-button>
      <span style="padding-left:10px">{{this.$route.query.className}}</span>
    </div>
    <el-row class="bg">
    <el-table
      :data="curriculumData">
      <el-table-column
        label="日期"
        width="120px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.courseDate}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="星期"
        width="100px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.week}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="开始时间"
        width="100px">
        <template slot-scope="scope">
          <span>{{scope.row.startTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="结束时间"
        width="100px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.endTime}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="课程名称"
        width="260px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="讲师"
        width="100px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.lecturer}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="职务"
        width="100px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.duty}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="单位"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.unit}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="教室"
        width="160px">
        <template slot-scope="scope">
          <span class="spani">{{scope.row.classroom}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="是否打卡"
        width="160px">
        <template slot-scope="scope">
          <span class="spani" v-if="scope.row.type == '0'">需要打卡</span>
          <span class="spani" v-else>不用打卡</span>
        </template>
      </el-table-column>
      </el-table>
    </el-row>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-size="pageSize"
      layout="total,sizes,  prev, pager, next, jumper"
      :total="total"
      class="pag"
    >
    </el-pagination>
  </div>
</template>

<script>
  import { curriculumList } from '@/api/user.js'
  import { mapMutations } from 'vuex'
  export default {
    name: 'Classinformation',
    data () { // 数据模型
      return {
        curriculumData: [],
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    },
    mounted() {
      this.curriculum()
    },
    methods: {
      ...mapMutations({
        saveUser: 'SAVE_USER'
      }),
      // 返回上一个页面
      go() {
         this.$router.go(-1)
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.curriculum()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.curriculum()
      },
      // 列表信息
       curriculum() {
          const query = {
            trainId: this.$route.query.id,
            pageNum: this.pageNum,
            pageSize: this.pageSize
          }
          curriculumList(query).then(resp => {
            if (resp.data.code === '0') {
                this.total = resp.data.data.total // 查询总条数
                this.curriculumData = resp.data.data.rows // 接收返回的数据
             }
          })
        }
    }
  }
</script>

<style>
#atlas{width: 100%;height:200px }
#allmap {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  [v-cloak] {
    display:none !important;
  }
  .bg{margin-top: 10px; padding: 20px; background-color: white;}
  .class{
    width: 100%;
    background-color: white;
    position: relative;
    margin-top: -10px;
  }
  .top{
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;
    align-items: center;
  }
  .num{
    color: #6f6f75;
  }
  .apply{
    position: absolute;
    left: 30px;
    margin: 0;
  }
  .inp{
    width: 250px;
    position: absolute;
    left: 180px;
    border: none;
  }
  .el-but{
    position: absolute;
    left: 440px;
  }
    .el-but2{ margin-left: 20px;
  }
  .left-tag p{
    margin-right: 30px;
  }
  .pag{position: absolute;right:10px}
  .big div{flex: 1}
  .download{margin:auto; width: 150px; line-height: 31px; display: block; border-radius:4px;
    color: #FFF; background-color: #409EFF; border-color: #409EFF;text-align:center;
    font-size:12px; text-decoration:none; cursor:pointer;}
  .download:hover{color: #FFF; background-color: #70b3f9; border-color: #70b3f9; text-decoration:none; }
</style>
